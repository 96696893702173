import React from 'react'

import * as PageHeadTemplates from '@arch-log/webapp.shared/Page/Head'
import * as SEO from 'components/SEO'
import * as Partials from 'partials/PrivateAccountPage'

/**
 */
export const Head = ({ pageContext }) => {
  return (
    <>
      <SEO.Default />
      <PageHeadTemplates.Language language={pageContext?.language} />
    </>
  )
}

/**
 */
const Page = () => {
  return <Partials.Default />
}

/**
 */
export default Page
