import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as DefaultComponents from './DefaultComponents'

/**
 */
export const FirstSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>プライベートアカウントとは</Title>
    <Description>
      プライベートアカウントに登録すると、自ら作成したコンテンツをArch-LOGのシステム上に掲載することができます。商品を登録する際にプライベートコンテンツであるという情報が自動的に付与されることで、そのオブジェクトは非公開のコンテンツとなり、許可したユーザーに対してのみ公開されるプライベートコンテンツとなります。自社で開発・作成したBIM用オブジェクトを入れて、自社のライブラリとして活用したい時にご活用いただけます。
    </Description>
  </Container>
)

/**
 */
export const SecondSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    FeeItemList = DefaultComponents.FeeItemList,
    FeeItem = DefaultComponents.FeeItem,
    FeeItemDescription = DefaultComponents.FeeItemDescription,
    PublicationFeeTable = DefaultComponents.PublicationFeeTable,
    PublicationFeeHead = DefaultComponents.PublicationFeeHead,
    PublicationFeeHeadline = DefaultComponents.PublicationFeeHeadline,
    PublicationFeeBody = DefaultComponents.PublicationFeeBody,
    PublicationFeeRow = DefaultComponents.PublicationFeeRow,
    PublicationFeeData = DefaultComponents.PublicationFeeData,
  } = {},
}) => (
  <Container>
    <Title>掲載価格</Title>
    <FeeItemList>
      <FeeItem>商品データ作成料</FeeItem>
      <FeeItemDescription>都度お見積もり対応</FeeItemDescription>
      <FeeItem>商品データ登録料</FeeItem>
      <FeeItemDescription>
        カタログデータ登録料：1商品あたり100円
        <br />
        3Dデータ登録料：1商品あたり200円
      </FeeItemDescription>
      <FeeItem>商品データ掲載料</FeeItem>
      <PublicationFeeTable>
        <PublicationFeeHead>
          <PublicationFeeRow>
            <PublicationFeeHeadline>点数</PublicationFeeHeadline>
            <PublicationFeeHeadline>月額(税抜)</PublicationFeeHeadline>
          </PublicationFeeRow>
        </PublicationFeeHead>

        <PublicationFeeBody>
          <PublicationFeeRow>
            <PublicationFeeData>5点迄</PublicationFeeData>
            <PublicationFeeData>2,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>10点迄</PublicationFeeData>
            <PublicationFeeData>3,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>20点迄</PublicationFeeData>
            <PublicationFeeData>5,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>50点迄</PublicationFeeData>
            <PublicationFeeData>10,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>100点迄</PublicationFeeData>
            <PublicationFeeData>15,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>200点迄</PublicationFeeData>
            <PublicationFeeData>25,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>500点迄</PublicationFeeData>
            <PublicationFeeData>55,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>1000点迄</PublicationFeeData>
            <PublicationFeeData>100,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>2000点迄</PublicationFeeData>
            <PublicationFeeData>150,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>3000点迄</PublicationFeeData>
            <PublicationFeeData>200,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>5000点迄</PublicationFeeData>
            <PublicationFeeData>300,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>10000点迄</PublicationFeeData>
            <PublicationFeeData>500,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>50000点迄</PublicationFeeData>
            <PublicationFeeData>750,000円</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>50001点以上</PublicationFeeData>
            <PublicationFeeData>1,000,000円</PublicationFeeData>
          </PublicationFeeRow>
        </PublicationFeeBody>
      </PublicationFeeTable>
    </FeeItemList>
  </Container>
)

/**
 */
export const ThirdSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>申し込み</Title>
    <Description>
      <Links.Contact.ToTop subject={'プライベートアカウントの申し込み'}>
        こちら
      </Links.Contact.ToTop>
      よりお問い合わせ下さい。
    </Description>
  </Container>
)

/**
 */
export const FourthSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>利用開始</Title>
    <Description>弊社営業担当より折り返しご連絡させて頂きます。</Description>
  </Container>
)
