import React from 'react'

import { Link } from '@arch-log/webapp.shared/Link'

import * as styles from './styles.module.scss'

/**
 */
export const Container = ({ children }) => {
  return <div className={styles.Container}>{children}</div>
}

/**
 */
export const Title = ({ children }) => {
  return <dt className={styles.Title}>{children}</dt>
}

/**
 */
export const Description = ({ children }) => {
  return <dd className={styles.Description}>{children}</dd>
}

/**
 */
export const FeeItemList = ({ children }) => {
  return <div className={styles.FeeItemList}>{children}</div>
}

/**
 */
export const FeeItem = ({ children }) => {
  return <dt className={styles.FeeItemList_Item}>{children}</dt>
}

/**
 */
export const FeeItemDescription = ({ children }) => {
  return <dd className={styles.FeeItemList_Description}>{children}</dd>
}

/**
 */
export const PublicationFeeTable = ({ children }) => {
  return <table className={styles.FeeTable}>{children}</table>
}

/**
 */
export const PublicationFeeHead = ({ children }) => {
  return <thead className={styles.FeeTable_FeeHead}>{children}</thead>
}

/**
 */
export const PublicationFeeBody = ({ children }) => {
  return <tbody className={styles.FeeTable_FeeBody}>{children}</tbody>
}

/**
 */
export const PublicationFeeHeadline = ({ children }) => {
  return <th className={styles.FeeTable_FeeHead_Headline}>{children}</th>
}

/**
 */
export const PublicationFeeRow = ({ children }) => {
  return <tr>{children}</tr>
}

/**
 */
export const PublicationFeeData = ({ children }) => {
  return <td className={styles.FeeTable_FeeBody_Data}>{children}</td>
}
