import React from 'react'

import * as Links from '@arch-log/webapp.shared/Links'
import * as DefaultComponents from './DefaultComponents'

/**
 */
export const FirstSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>What is a private account?</Title>
    <Description>
      By registering for a private account, you can post your own content on the
      Arch-LOG system. When you register a product, the information that it is
      private content is automatically added, so that the object becomes private
      content. It will be private content that will be published only to
      authorized users. Put the BIM object developed and created in-house, You
      can use it when you want to use it as your own library.
    </Description>
  </Container>
)

/**
 */
export const SecondSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    FeeItemList = DefaultComponents.FeeItemList,
    FeeItem = DefaultComponents.FeeItem,
    FeeItemDescription = DefaultComponents.FeeItemDescription,
    PublicationFeeTable = DefaultComponents.PublicationFeeTable,
    PublicationFeeHead = DefaultComponents.PublicationFeeHead,
    PublicationFeeHeadline = DefaultComponents.PublicationFeeHeadline,
    PublicationFeeBody = DefaultComponents.PublicationFeeBody,
    PublicationFeeRow = DefaultComponents.PublicationFeeRow,
    PublicationFeeData = DefaultComponents.PublicationFeeData,
  } = {},
}) => (
  <Container>
    <Title>Listed price</Title>
    <FeeItemList>
      <FeeItem>Product data creation fee</FeeItem>
      <FeeItemDescription>Estimate support each time</FeeItemDescription>
      <FeeItem>Product data registration fee</FeeItem>
      <FeeItemDescription>
        Catalog data registration fee: 100 yen per product
        <br />
        3D data registration fee: 200 yen per product
      </FeeItemDescription>
      <FeeItem>Product data publication fee</FeeItem>
      <PublicationFeeTable>
        <PublicationFeeHead>
          <PublicationFeeRow>
            <PublicationFeeHeadline>Score</PublicationFeeHeadline>
            <PublicationFeeHeadline>
              Monthly amount (excluding tax)
            </PublicationFeeHeadline>
          </PublicationFeeRow>
        </PublicationFeeHead>

        <PublicationFeeBody>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 5 points</PublicationFeeData>
            <PublicationFeeData>2,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 10 points</PublicationFeeData>
            <PublicationFeeData>3,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 20 points</PublicationFeeData>
            <PublicationFeeData>5,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 50 points</PublicationFeeData>
            <PublicationFeeData>10,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 100 points</PublicationFeeData>
            <PublicationFeeData>15,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 200 points</PublicationFeeData>
            <PublicationFeeData>25,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 500 points</PublicationFeeData>
            <PublicationFeeData>55,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 1000 points</PublicationFeeData>
            <PublicationFeeData>100,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 2000 points</PublicationFeeData>
            <PublicationFeeData>150,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 3000 points</PublicationFeeData>
            <PublicationFeeData>200,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 5000 points</PublicationFeeData>
            <PublicationFeeData>300,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 10000 points</PublicationFeeData>
            <PublicationFeeData>500,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>Up to 50000 points</PublicationFeeData>
            <PublicationFeeData>750,000yen</PublicationFeeData>
          </PublicationFeeRow>
          <PublicationFeeRow>
            <PublicationFeeData>50001 points or more</PublicationFeeData>
            <PublicationFeeData>1,000,000yen</PublicationFeeData>
          </PublicationFeeRow>
        </PublicationFeeBody>
      </PublicationFeeTable>
    </FeeItemList>
  </Container>
)

/**
 */
export const ThirdSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Application</Title>
    <Description>
      Please contact us from
      <Links.Contact.ToTop subject={'Apply for a private account'}>
        here.
      </Links.Contact.ToTop>
    </Description>
  </Container>
)

/**
 */
export const FourthSection = ({
  components: {
    Container = DefaultComponents.Container,
    Title = DefaultComponents.Title,
    Description = DefaultComponents.Description,
  } = {},
}) => (
  <Container>
    <Title>Start using</Title>
    <Description>Our sales staff will contact you shortly.</Description>
  </Container>
)
